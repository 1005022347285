/* purgecss start ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* purgecss end ignore */
@import "references";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

/* fonts */
@font-face {
    font-family: "Nexa_Free_Light";
    src: url("../public/fonts/Nexa_Free_Light.otf");
    font-display: swap;
}


$color-primary: #ff5e00;
$color-bright: #ff5e00;
$color-primary-shade: #ff7300;
$color-success: #4cb050;

html {
    scroll-behavior: smooth;
}

* {
    font-family: "Nexa_Free_Light";
    /*font-family: "Roboto", sans-serif;*/
}

*,
*:focus,
*:hover {
    outline: none;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
h1, h2 {
    font-size : 50px;
    @media screen and (max-width:981px){
        font-size: 25px;
    }
}

h1, h1 span,
h2,
h3,
h4,
h5,
h6{
    font-weight:100;
    font-family: "Nexa_Free_Light";
}
a:link {
    color: $color-bright;
}

.article-area{ 
    color :#333333;
    a:link {
        color: #333333;
    }
}

p, p span{
    font-family: "Roboto", sans-serif;
    line-height: 1.8;
}


footer .text-color-orange{
    color : #ff711f;
    a:link{
        color : #ff711f;
    }
}




.card-article {
    transition: all linear 0.2s;

    .card-hover-element {
        visibility: hidden;
        opacity: 0;
        transition: all linear 0.2s;
    }

    span {
        left: 50%;
        top: 50%;
        transform: scaleX(1);
        transition: all linear 0.2s;
    }

    &:hover {
        transform: translateY(-3px);

        .card-hover-element {
            visibility: visible;
            opacity: 1;

            span {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) scaleX(1);
            }
        }
    }
}

.svg-img {
    display: block;
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.img-section {
    @media only screen and (min-width: 992px) {
        position: absolute;
        left: -20%;
        width: 500px;
        height: auto;
        top: 50%;
        transform: translateY(-50%);
    }
}

.menu-fixed {
    display: none;

    a {
        font-family: "Nexa_Free_Light" !important;
    }

    &.active {
        position: fixed;
        top: 50%;
        transform: translate(-247px, -50%);
    }

    &.in {
        position: absolute;
        top: 380px;
        transform: translateX(-247px);
    }

    @media screen and (min-width: 992px) {
        display: block;
    }
}

.card-cus {
    height: 150px;
    width: 100%;
}


.fond-gris {
    &::before {
        content: "";
        background: rgba(60, 58, 56, 0.9);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 50;
    }
}
.bg-gray{
    background: rgba(243, 243, 243, 0.79);
}

.btn-primary {
    color: $color-primary;
    border: 1px solid $color-primary;
    background-color: #fff;
    transition: all linear 0.2s;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;

    &:hover {
        color: #fff;
        background-color: $color-primary;
    }
}

.btn-secondary {
    color: white;
    border: 1px solid $color-bright;
    background-color: $color-bright;
    transition: all linear 0.2s;
    padding: 0.375rem 0.75rem;

    &:hover {
        color: $color-bright;
        background-color: white;
    }
}

.nav-item {
    display: flex;
    padding: 15px 23.5px 15px;
    font-size: 15px;
    font-weight: 400;
    align-items: center;
    height: 100%;

    &:hover {
        background: #242424;
    }

    a {
        color: white;
    }

    &.active {
        border-bottom: 3px solid $color-primary-shade;

        a {
            color: $color-primary-shade;
        }
    }
}

/*.md-input-main {
    width: 50%;
    font-size: 1.25rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}*/

.md-input-box {
    position: relative;
}

.md-input {
    width: 100%;
    outline: none;
    min-height: 50px;
    background: transparent;
}

.md-label {
    display: block;
    color: $color-primary;
    position: absolute;
    pointer-events: none;
    transform-origin: top left;
    transform: translate(0, -40px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.md-label-focus {
    color: $color-primary;
    transform: translate(0, -65px) scale(0.75);
    transform-origin: top left;
}

.md-input-underline {
    border-bottom: 1px solid #555;
}

.md-input-underline:after {
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
    bottom: -0.05rem;
    content: "";
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: scaleX(0);
    border-bottom: 2px solid $color-primary;
}

.md-input:focus ~ .md-input-underline:after {
    transform: scaleX(1);
}

.md-input:focus + .md-label,
.md-input:not(:placeholder-shown) + .md-label {
    color: $color-primary;
    transform: translate(0, -65px) scale(0.75);
    transform-origin: top left;
}

.itemList {
    list-style: disc;
    padding-left: 20px;
    margin-left: 10px;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.orange {
    color: $color-primary;
}

#social {
    a {
        border-radius: 100px;
        margin: 15px;
        width: 30px;
        height: 30px;
        padding: 0;
        border: 1px solid transparent;
        -webkit-transition: all linear 0.2s;
        transition: all linear 0.2s;
        display: inline-block;

        > svg {
            margin-top: 6px;
            max-height: 16px;
            /* margin-left: 6px; */
        }
    }

    .facebook-link {
        color: #fff;
        background-color: #3b5998;
    }

    .twitter-link {
        color: #fff;
        background-color: #55acee;
    }

    .google-link {
        color: #fff;
        background-color: #dd4b39;
    }

    .linkdn-link {
        color: #fff;
        background-color: #007bb5;
    }
}

// Alphalives

main {
    position: relative;
    z-index: 5;
}

.fa.fa-caret-right {
    width: 6px;
    height: 16px;
}

.center-line {
    &:before {
        @media screen and (min-width: 992px) {
            content: "";
            position: absolute;
            left: 48%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 2px;
            height: 100%;
            background: #ff7300;
        }
    }
}

//////////////////////////////////////////////////////////////////////////////

.img-nav {
    height: 27px;
    width: 110px;
}

.header {
    position: fixed;
    height: 55px;
    /* height: auto; */
    z-index: 1001;
    top: 0;
    left: 0;
}

@media screen and (min-width: 992px) {
    .header {
        // opacity: 0;
        transition: opacity linear 1s;
    }

    #big-zone h2 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 992px) {
    .header {
        background-color: rgba(51, 51, 51, 1);
    }
}

.breadcrumb {
    background-color: transparent !important;
}

#logo,
#logo-m {
    transition: all linear 0.5s;
    padding: 20px;
    width: 150px;
    position: absolute;
}

.logo-link {
    transition: all linear 1s;
    position: absolute;
    top: 0;
}

#logo #black-letter path,
#logo #black-letter polygon,
#logo #black-letter polyline,
#logo #black-letter rect {
    fill: #fff;
}



@media screen and (min-width: 992px) {
    #logo #black-letter.black path,
    #logo #black-letter.black polygon,
    #logo #black-letter.black polyline,
    #logo #black-letter.black rect {
        fill: #000;
    }

    #logo #orange.white path,
    #logo #orange.white polygon,
    #logo #orange.white polyline {
        fill: #fff;
    }

    #logo #grey.white {
        fill: #fff;
    }
}

@media screen and (max-width: 544px) {
    .block-xs {
        display: block !important;
    }
}

#icone {
    right: 20px;
    margin: 0;
    position: fixed;
    top: 85%;
    z-index: 2;
}

#icone a {
    display: block;
    margin: 20px 20px;
    color: #fff;
}

.link-hr {
    height: 1px;
    background-color: #ff7300;
    margin-top: 13px;
}

.link-plus {
    font-weight: bold;
    font-size: 18px;
}

.link-plus:hover {
    color: #000;
    text-decoration: none;
}

@media (max-width: 480px) {
    .horizon-title-xs {
        font-size: 13px;
        font-weight: bold;
        padding-right: 0px;
    }
}

.block-scroll {
    position: absolute;
}

#service-roll,
.padding-title {
    padding-right: 40px !important;
}
.homeHolder{
    position:absolute;
    top:0;
    right: 10px;
}
.homeIcon{
    width: 25px !important;
    height: 25px !important;
    transition: all 0.25s;
    text-align: center;
    margin: 17px 15px 0px 0px;
    color: #fff;
    fill: #fff;
    &.orange{
        color: $color-bright;
        fill: $color-bright;
    }
    &.black{
        color: #000;
        fill: #000;
    }
}

.menu-collapsed {
    transition: all 0.25s;
    position: fixed;
    width: 60px;
    height: 70px;
    top: 0;
    right: 0;
    cursor: pointer;
}

.menu-collapsed ul {
    transition: all 0s;
    position: fixed;
    left: 9000px;
}

.menu-collapsed .social-menu {
    display: none;
}

.menu-collapsed .social-menu span {
    display: none;
}

.menuButtonHandler {
    position: fixed;
    content: "";
    width: 66px;
    height: 66px;
    right: 0;
    top: 0;
}

.bar.button {
    cursor: pointer;
    z-index: 2000;
    margin-top: 5px;
    position: fixed;
    right: 25px;
    top: 25px;
    height: 2px !important;
    width: 30px;
    border-radius: 15px;
    background-color: #fff;
    transition: all 0.5s;
    padding: 0 !important;
}

.bar:before {
    transition: all 0.5s;
    content: "";
    position: absolute;
    right: 0;
    top: -8px;
    height: 2px;
    width: 15px;
    border-radius: 15px;
    background-color: #fff;
}

.bar:after {
    transition: all 0.5s;
    content: "";
    position: absolute;
    right: 0;
    top: 8px;
    height: 2px;
    width: 30px;
    border-radius: 15px;
    background-color: #fff;
}

.bar.button.transformation,
.transformation.bar:after,
.transformation.bar:before {
    right: 150vw;
}

.bar.swap {
    background-color: #000;
}

.bar.swap:before {
    background-color: #000;
}

.bar.swap:after {
    background-color: #000;
}

.menu-expanded {
    transition: all 0.25s;
    text-align: center;
    line-height: calc(95vh / 12);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    cursor: initial;
    background: rgba(47, 46, 44, 1);
}

.menu-expanded nav {
    height: 100vh;
    width: 100vw;
}

.menu-expanded nav div {
    margin-top: auto;
    margin-bottom: auto;
}

.menu-expanded ul {
    left: 0;
    width: 100%;
    padding: 0;
    position: relative;
}

.menu-expanded ul li {
    list-style: none;
    margin-right: 10%;
    text-align: right;
    font-size: 25px;
}

.menu-expanded ul li .catg {
    width: 100%;
    float: left;
    transition: all linear 0.2s;
}

.menu-expanded ul li .effect {
    width: 0%;
    height: 2px;
    float: left;
    background-color: #fff;
    margin-top: 35px;
    margin-left: 0%;
    transition: all linear 0.2s;
}

.menu-expanded a {
    color: #fff;
}

.menu-expanded a:hover,
.menu-expanded a:focus {
    color: #ff7300;
    text-decoration: none;
}

.menu-expanded a:hover .catg,
.menu-expanded a:focus .catg {
    width: 95%;
}

.menu-expanded a:hover .effect,
.menu-expanded a:focus .effect {
    background-color: #ff7300;
    width: 3%;
    margin-left: 2%;
}

.menu-expanded .bar {
    background-color: transparent;
}

.menu-expanded .bar:before {
    transform: rotate(45deg);
    width: 30px;
    top: 0px;
    background-color: #fff;
}

.menu-expanded .bar:after {
    transform: rotate(-45deg);
    top: 0px;
    background-color: #fff;
}

.menu-expanded .social-menu {
    /* display: grid; */
    position: relative;
    bottom: 50px;
    width: 80%;
    margin-left: 10%;
    float: left;
}

.menu-expanded .social-menu span {
    display: block;
    height: 50px;
    color: #fff;
    width: 100%;
    float: left;
    text-align: left;
    color: #ff7300;
}

.menu-expanded .social-menu a {
    color: #ff7300;
    text-align: center;
    padding: 15px;
}

.menu-expanded .social-menu a i {
    font-size: 1.3em;
}

.menu-expanded .social-menu .socials {
    text-align: right;

    & svg {
        font-size: 1.3em;
    }
}

.contact-link {
    float: right;
    position: relative;
    margin-right: 90px;
    top: 10px;
    /* border: 1px solid #ff7300; */
    padding: 7px 15px;
    line-height: 25px;
    font-size: 16px;
    box-sizing: border-box;
    height: 40px;
    transition: all linear 0.2s;
    text-decoration: none !important;
    color: #ff7300 !important;

    @media screen and (max-width: 544px) {
        margin-right: 75px;
        font-size: 14px;
        height: 36px;
    }

    .inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:before,
        &:after {
            top: 0;
            width: 2px;
            height: 100%;
            -webkit-transform: scaleY(0);
            transform: scaleY(0);
            background-color: #ff7300;
            position: absolute;
            content: "";
            display: block;
            -webkit-transition: -webkit-transform 0.3s;
            transition: -webkit-transform 0.3s;
            transition: transform 0.3s;
            transition: transform 0.3s, -webkit-transform 0.3s;
        }

        &:before {
            -webkit-transform-origin: top center;
            transform-origin: top center;
            left: 0;
        }

        &:after {
            -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
            right: 0;
        }
    }

    &:before,
    &:after {
        position: absolute;
        content: "";
        background-color: #ff7300;
        left: 0;
        width: 100%;
        height: 2px;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        display: block;
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
    }

    &:before {
        -webkit-transform-origin: right center;
        transform-origin: right center;
        top: 0;
    }

    &:after {
        -webkit-transform-origin: left center;
        transform-origin: left center;
        bottom: 0;
    }

    &:hover {
        &:before {
            -webkit-transform-origin: left center;
            transform-origin: left center;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        &:after {
            -webkit-transform-origin: right center;
            transform-origin: right center;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        .inner:before {
            -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        .inner:after {
            -webkit-transform-origin: top center;
            transform-origin: top center;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    &.black {
        color: #000 !important;

        &:before,
        &:after,
        & .inner:after,
        & .inner:before {
            background-color: #000;
        }
    }
}

@media screen and (max-width: 768px) {
    .bar,
    .bar:before,
    .bar:after {
        background-color: #fff !important;
    }

    .menu-expanded {
        background: rgba(47, 46, 44, 0.9);
    }

    .header.openedMenu {
        background: transparent;
    }

    .menu-expanded nav {
        background: none;
    }

    .menu-expanded .bar {
        background-color: transparent !important;
    }
}

.zone-article ul {
    margin-bottom: 45px;
    padding-left: 40px;
}

.fa-caret-right {
    color: #ff7300;
    margin-right:10px;
}

figcaption{
    font-weight: 100;
}
.big-zone-service li {
    list-style: none;
}
#big-zone{
    .line-scroll {
        position: absolute;
        width: 2px;
        height: 100vh;
        left: 48%;
        top: 0;
        margin-left: -1px;
        overflow: hidden;
        background-color: #333;
        z-index: -1;
        .line {
            top: 0px;
            bottom: auto;
            z-index: 11;
            display: block;
            position: absolute;
            left: 50%;
            width: 2px;
            height: 0%;
            margin-left: -1px;
            background: rgba(255, 115, 0, 1) !important;
            background: -webkit-linear-gradient(top, rgba(255, 115, 0, 1) 50%, rgba(255, 115, 0, 0) 50%);
            background: linear-gradient(to bottom, rgba(255, 115, 0, 1) 50%, rgba(255, 115, 0, 0) 50%);
            background-size: 100% 200%;
            background-position: 0 -80px;
            -moz-transform: translateY(-2px);
            -o-transform: translateY(-2px);
            -ms-transform: translateY(-2px);
            -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
        }
    }
}

.lightHeader {
    @media screen and (min-width: 992px) {
        .header {
            opacity: 0;
            transition: opacity linear 1s;
        }
    }

    .logoLight {
        transition: all linear 0.5s;
        padding: 20px;
        width: 150px;

        & g:first-of-type {
            & path,
            & polygon,
            & polyline,
            & rect {
                fill: #fff;
            }
        }
    }
}

// Aside//////////////////////////////////////////////////////////////////////////////////
.nav-bar {
    width: 130px;
    transition: all linear 0.5s;

    &:hover .nav-menu .scrollTo, &:hover .nav-menu a  {
        transition: all linear 0.5s;
        opacity: 1;
        width: 100%;
    }

    .bloc-menu {
        margin-top: auto;
        margin-bottom: auto;
        display: block;
        width: 100%;
    }

    .nav-range {
        display: flex;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;

        &.actif {
            hr {
                width: 100%;
                border-top: 1px solid #ff7300;
            }

            a, .scrollTo {
                color: #ff7300;
            }
        }
    }

    .nav-menu {
        text-align: right;
        font-size: 10px;
        /* width: 58.3333%; */
        width: 62.3333%;

        a, .scrollTo {
            text-align: right;
            color: #999;
            transition: all linear 0.5s;
            opacity: 0;
            width: 0;
            cursor: pointer;
        }
    }

    .nav-fil {
        padding-right: 0;
        padding-left: 10px;
        display: flex;
        /* width: 41.6667%; */
        width: 36.3333%;

        hr {
            transition: all linear 0.5s;
            border-top: 1px solid #999;
            margin-left: 0 !important;
            margin-top: auto;
            margin-bottom: auto;
            border-top-width: 1px !important;
            width: 20%;
        }
    }

    &.white {
        .arrow-up {
            color: #333;
        }

        .arrow-down {
            color: #fff;
        }

        .nav-menu .scrollTo, .nav-menu a {
            color: #333;
        }

        .nav-fil hr {
            border-top: 1px solid #333;
        }

        .nav-range.actif {
            .scrollTo {
                color: #fff;
            }

            hr {
                border-top: 1px solid #fff;
            }
        }

        .nav-icon a, .nav-icon .scrollTo{
            color: #fff;

            &:hover {
                color: #000;
            }

            &:focus {
                color: initial;
            }
        }
    }

    &.black {
        .arrow-up {
            color: #fff;
        }

        .arrow-down {
            color: #fff;
        }

        .nav-menu .scrollTo, .nav-menu a {
            color: #fff;

            &:hover {
                color: #ff7300;
            }
        }

        .nav-fil hr {
            border-top: 1px solid #fff;
        }

        .nav-range.actif {
            a, .scrollTo {
                color: #ff7300;
            }

            hr {
                border-top: 1px solid #ff7300;
            }
        }

        .nav-icon a, .nav-icon .scrollTo {
            color: #fff;

            &:hover {
                color: #ff7300 !important;
            }

            &:focus {
                color: #ff7300 !important;
            }
        }
    }

    &.grey {
        .arrow-up {
            color: #000;
        }

        .arrow-down {
            color: #999;
        }

        .nav-menu .scrollTo, .nav-menu a {
            color: grey;

            &:hover {
                color: #000;
            }
        }

        .nav-fil hr {
            border-top: 1px solid #999;
        }

        .nav-range.actif {
            a, .scrollTo {
                color: #000;
            }

            hr {
                border-top: 1px solid #000;
            }
        }

        .nav-icon a, .nav-icon .scrollTo {
            color: #000;

            &:hover {
                color: #999 !important;
            }

            &:focus {
                color: #000 !important;
            }
        }
    }
}

.mt10 {
    margin-top: 10px;
}

.erreur {
    color: red;
    font-size: small;
    text-align: center;
    margin-bottom: 10px;
}

.left-custom-center {
    left: 50%;

    &.in {
        left: 50%;
    }
}
// Fil D'ariane
.breadcrumb {
    background-color: transparent !important;
    list-style: none;

    * {
        font-family: "Nexa_Free_Light";
    }

    &:not(.notAbsolute){
        position: absolute;
        top: 2px;
        right: 230px;
        z-index: 1010;
        padding: 0.75rem 1rem;
        margin-bottom: 1rem;
        border-radius: 0.25rem;

        > li {
            &:before {
                color: #fff !important;
            }

            float: left;

            a {
                color: #fff;
                font-size: 12px;
                text-decoration: none;
                line-height: 1.5;

                &:hover {
                    color: #ff7300;
                    font-size: 12px;
                }
            }
        }

        > li + li:before {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            color: #818a91;
            content: "/";
        }
    }
    &.notAbsolute{
        > li {

            float: left;

            a {
                color: #ff5e00;
                text-decoration: none;
                line-height: 1.5;

                &:hover {
                    color: #ff7300;
                    text-decoration: underline;
                }
            }
        }

        > li + li:before {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            color: #818a91;
            content: "›";
        }
    }
}

.documentReact{
    .asset{
        position:relative;
        padding-bottom:300px;
        width:100%;
    } 
    ul {
        padding-left: 40px;
        list-style: none;
        li:before{
            content: "";
            position: absolute;
            width: 7px;
            transform: translateX(-15px) translateY(3px);
            z-index: 54;
            -webkit-mask-image: url('/imgs/caret-right.svg');
            mask-image: url('/imgs/caret-right.svg');
            background-color:$color-bright;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            background-size: contain;
            background-repeat: no-repeat;
            height: 19.5px;
        }
    }
    h3{
        text-align:left !important;
    }
}

.contactMinScreen {
    min-height: calc(100vh - 178px);
}
.scrollTo {
    cursor: pointer;
}

/** BigZone - Nos Services **/
.zone-nos-services {
    min-height: 100vh;
    align-items: center;
}
.font-500 {
    font-weight: 500;
}
/*
.container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    margin-right: auto;
    margin-left: auto;
    @media screen and (min-width: 1200px) {
        max-width: 1140px;
    }

}*/

#zone1 svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    min-width: 100%;
    min-height: 100vh;
    width: auto;
    height: auto;
}
#headerSection, #digital-title, #nav-bar, #digital-scroll{
    /*opacity:0;*/
}
.link-global{
    &:hover{
        color:#ff7300;
    }
}
.content-scroll-down {
    position: absolute;
    z-index: 99;
    color: #fff;
    bottom: 0;
    left: 48%;
    transform: translate(-51%,-50%);
    .block-round {
        height: 33px;
        width: 23px;
        border: 2px solid #fff;
        border-radius: 50px;
        margin: auto;
        position: relative;
        &:after {
            content: "";
            display: block;
            width: 4px;
            height: 4px;
            background-color: #fff;
            position: absolute;
            top: 30%;
            left: 40%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
            animation-name: indicator;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
        }
        @keyframes  indicator {
            0% {
              transform: translateY(0);
            }
            50% {
              opacity: .7;
            }
            100% {
              opacity: 0;
              transform: translateY(20px);
            }
          }
    }
    .content-text {
        margin-top: 5px;
        font-size: 10px;
        font-weight: 100;
        opacity: 0.7;
        &:after {
            content: "";
            display: block;
            height: 25px;
            width: 1px;
            background-color: #fff;
            position: absolute;
            left: 50%;
        }
    }
    
    &.orange{
        color: $color-bright;
        .block-round {
            border: 2px solid $color-bright;
        }
        .block-round:after, .content-text:after {
            background-color: $color-bright;
        }
    }
}
.link-read-plus {
    font-weight: 900;
    color: #ff7300;
    display: flex;
    align-items: center;
    .dvi-hr {
        flex-basis: 40%;
        background-color: red;
        height: 1px;
        margin-left: 11px;
    }
    &:hover{
        color: #000;
    }
}
#zone4{
    min-height: 100vh;
    .img-user-alphalives {
        opacity: 0.2;
        position: absolute;
        height: 100vh;
        z-index: -1;
        left: 0;
        top: 0;
        width: 600px;
    }
}
#zone5 {
    min-height: 100vh;
    padding: 0px 0;
}
#zone7{
    height: calc(100vh - 177px);
}

#siblingLink{
    background: url('https://images.ctfassets.net/a39bg4ghqkpe/70Ytqv6qI4z0yKmxyKGr9U/c46d33c2772bda0c86ab16b8fc8cd143/met-02.jpg?q=50&fm=webp&w=800') center no-repeat;
    background-size: cover;
    .light-greybanner{
        position:relative;
        display:flex;
        min-height:50vh;
        background: rgba(60, 58, 56, .9);
    }
}
hr{
    &.orange{border-color:$color-bright;}
    &.white{border-color: #fff;}
    &.black{border-color: #000;}
}

@-webkit-keyframes map-effect {
    0% {r: 10;}
  100% {r: 45;}
}

.mapContainer{
    height:100vh;
    position: relative;
    .container{
        height:100%;
        width:100%;
        >div{
          background:linear-gradient(90deg, #fff, transparent);
        }
        *{
            font-family: Nexa_Free_Light;
            z-index: 10;
            font-weight:100;
        }
        .icon {
            margin-right: 5px;
            color: #ff7300;
        }
        .link-plus{
            font-weight: bold;
            font-size: 18px;
            color: $color-bright;
        }
        img{
            display: inline-block;
        }
        .btn-contact:hover{color: $color-bright;}
        .contact_title{color: #333;font-size:25px;margin-bottom:0;span{color: $color-bright;}}
        .contact_field{font-size:20px;margin-left:25px;color: #333;}
        .phone-number{font-size:20px;/*margin-left:25px;*/}
        p{color: $color-bright;font-size: 35px;}
        li{list-style: none;font-size:14px;line-height:24px;}
    }
    .contactMap{
        position: absolute;
        right:0;
        z-index: 1;
        height: 100%;
        *{
            z-index: 1;
        }
        #middle-c{
            animation: map-effect 2s ease-in-out infinite;
        }
    }
    @media screen and (max-width:992px){
        background: url('/img/contact_font.jpg') 60% no-repeat;
        #map {
            display:none;
        }
    }
}
.author-area{
    background-color: rgba(243,243,243,.79);
    p{
        margin:0;
    }
    .linkedin{
        color: #3b5998;
    }
}
/****
* SVG Position
*/
#ecransvg {
    position: absolute;
    width: 80.5%;
    right: 35%;
    bottom: -200%;
    @media screen and (max-width: 992px){
        bottom: 0;
        width: 130%;
        right: 10%;
    }
}
#phonesvg{
    position: absolute;
    width: 150%;
    right: 0%;
    bottom: -250%;
    transform: rotate(45deg);
    @media screen and (max-width: 992px){
        width: 300%;
        right: -65%;
    }
    @media screen and (max-width: 544px){
        bottom: 20%;
        width: 400%;
        right: -140%;
        transform: rotate(0deg);
    }
}
#ordisvg{
    position: absolute;
    width: 120%;
    right: 50%;
    bottom: -200%;
    @media screen and (max-width: 992px) {
        bottom: 0;
        width: 170%;
        right: 10%;
    }
    @media screen and (max-width: 544px) {
        bottom: 25%;
        width: 300%;
        right: 0%;
    }
}
/****
* SVG Animation
*/
@keyframes dash{
	to{
		stroke-dashoffset: 0;
	}
}
.zoneContact{
    background: rgb(255 255 255 / 20%);
    padding: 10px;
    border-radius: 7px; 
}
@media screen and (min-width:2600px){
    .left-custom-center.in {
        left: 49%;
    }
    .left-custom-center {
        left: 49%;
    }
}
.img-section-quote-1 {
    position: absolute;
    top: -17px;
    left: -73px; 
    opacity: 0.5;
}
.img-section-quote-2 {
    position: absolute;
    right: -98px;
    bottom: -10px;
    opacity: 0.5;
}
.btn-contact{
    color: #fff !important;
    &:hover{
        color: #ff5e00  !important;
    }
}
.text-base-top p.text-base br {
    display: none;
}
.text-base-top p.text-base {
    text-align: center;
}
section.image-fixed {
    
    h2{
        line-height: normal;
    }
    
    &:not(.unfix) > span:first-child{
        position: fixed !important;
    }
} 
.menu-sidebar-page {
    list-style-type: none;
    margin-top: 15px;
    padding-left: 9px;
  li {
        margin-bottom: 10px;
        font-size: 16px;
    }
    li:before {
        content: "";
        position: absolute;
        width: 7px;
        transform: translateX(-15px) translateY(3px);
        z-index: 54;
        -webkit-mask-image: url(/imgs/caret-right.svg);
        mask-image: url(/imgs/caret-right.svg);
        background-color: #ff5e00;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
        height: 19.5px;
    }
}
.container-siderbar {
    margin-top: 20px;
}
.active-link-color{
    color:#ff5e00;
    font-weight: bold;
    transition: color 0.5s;
}
.menu-sidebar-page li a:hover {
  transition: color 0.5s;
  color:#ff5e00;
}